import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useAxios } from '../lib/axios';
import { useQuery } from '../lib/shared/hooks';
import {
    CustomerDataResponseDto,
    SubmitIbanResponseDto,
    ValidLinkIdResponseDto,
} from '../lib/shared/types';
import { ProgressBreadcrumbs } from './components/progress-breadcrumbs';
import './main-page.scss';
import { LinkIdInvalidView } from './views/linkid-invalid-view';
import { PostcodeCheckView } from './views/postcode-check-view';
import { SubmittedView } from './views/submitted-view';
import { UserFormView } from './views/user-form-view';
import { VerificationFailedView } from './views/verification-failed-view';

interface PostcodeValues {
    postcode: string;
}

interface UserFormValues {
    iban: string;
    sepaAccepted: boolean;
    privacyNoticeAccepted: boolean;
    brokerContractAccepted: boolean;
}

export const MainPage: React.FC = () => {
    const axios = useAxios();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLinkIdValid, setIsLinkIdValid] = useState<boolean>(false);
    const [postcode, setPostcode] = useState<string | null>(null);
    const [customerData, setCustomerData] = useState<CustomerDataResponseDto | null>(null);
    const [processNumber, setProcessNumber] = useState<string | null>(null);

    const linkId = useQuery().get('linkid');

    const onPostcodeInput = async (values: PostcodeValues): Promise<void> => {
        setPostcode(values.postcode);
        setIsLoading(true);
        try {
            const response = await axios.get<CustomerDataResponseDto>('verify-with-postcode', {
                params: {
                    linkId: linkId,
                    postcode: values.postcode,
                },
            });

            setCustomerData(response.data);
        } catch (e) {
            setCustomerData(null);
        } finally {
            setIsLoading(false);
        }
    };

    const onUserFormInput = async (values: UserFormValues): Promise<void> => {
        setIsLoading(true);
        console.debug(values);
        console.debug(linkId);
        try {
            const response = await axios.post<SubmitIbanResponseDto>('submit-iban', null, {
                params: {
                    linkId: linkId,
                    iban: values.iban,
                    sepaAccepted: values.sepaAccepted,
                    privacyNoticeAcceepted: values.privacyNoticeAccepted,
                    brokerContractAccepted: values.brokerContractAccepted,
                },
            });

            setProcessNumber(response.data.processNumber);
        } catch (e) {
            setProcessNumber(null);
        } finally {
            setIsLoading(false);
        }
    };

    const onRetryPostcode = (): void => {
        setPostcode(null);
        setCustomerData(null);
    };

    useEffect(() => {
        const isLinkIdValid = async (linkId: string | null): Promise<void> => {
            setIsLoading(true);
            try {
                const response = await axios.get<ValidLinkIdResponseDto>('api/is-linkid-valid', {
                    params: {
                        linkId: linkId,
                    },
                });

                setIsLinkIdValid(!!response.data?.isValid);
            } catch (e) {
                setIsLinkIdValid(false);
            } finally {
                setIsLoading(false);
            }
        };

        isLinkIdValid(linkId);
    }, [axios, linkId]);

    const showPostcodeView =
        !isLoading &&
        isLinkIdValid &&
        postcode === null &&
        customerData === null &&
        processNumber === null;
    const showVerificationFailedView =
        !isLoading &&
        isLinkIdValid &&
        postcode !== null &&
        customerData === null &&
        processNumber === null;
    const showUserFormView =
        !isLoading &&
        isLinkIdValid &&
        postcode !== null &&
        customerData !== null &&
        processNumber === null;

    const progress = processNumber !== null ? 3 : showUserFormView ? 2 : 1;

    return (
        <div className='main-page-container'>
            <div className='main-page-progress-container'>
                <ProgressBreadcrumbs stage={progress} />
            </div>
            <div className='main-page-content-container'>
                {isLoading && (
                    <div className='main-page-loading-container'>
                        <LoadingOutlined className='main-page-loading' />
                    </div>
                )}
                {!isLoading && !isLinkIdValid && <LinkIdInvalidView />}
                {showPostcodeView && (
                    <div>
                        <PostcodeCheckView onFormFinish={onPostcodeInput} />
                    </div>
                )}
                {showVerificationFailedView && (
                    <div>
                        <VerificationFailedView onRetry={onRetryPostcode} />
                    </div>
                )}
                {showUserFormView && (
                    <div>
                        <UserFormView customerData={customerData} onFormFinish={onUserFormInput} />
                    </div>
                )}
                {processNumber !== null && (
                    <div>
                        <SubmittedView processNumber={processNumber} />
                    </div>
                )}
            </div>
        </div>
    );
};
