import { notification } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import './app.less';
import { AxiosProvider } from './lib/axios';
import { AutoEinsApp } from './main-layout';

export const App = (): React.ReactElement => {
    notification.config({ placement: 'bottomRight' });

    return (
        <AxiosProvider>
            <BrowserRouter>
                <AutoEinsApp />
            </BrowserRouter>
        </AxiosProvider>
    );
};

export default App;
