import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Divider, Form, Input, Row, Space, Typography } from 'antd';
import { useState } from 'react';
import { ShieldCheckIcon, ShieldFillIcon } from '../../../lib/shared/icons';
import { CustomerDataResponseDto } from '../../../lib/shared/types';
import { ibanValidator, normalizeIban } from '../../../lib/shared/validations';
import { Documents } from './documents';
import './user-form-view.scss';

const { Item } = Form;
const { Link, Paragraph, Title } = Typography;

interface Props {
    customerData: CustomerDataResponseDto;
    onFormFinish: (values: FormRules) => void | Promise<void>;
}

interface FormRules {
    iban: string;
    sepaAccepted: boolean;
    privacyNoticeAccepted: boolean;
    brokerContractAccepted: boolean;
}

const formatCurrency = (value: number): string => {
    return value.toLocaleString('de-DE', {
        currency: 'EUR',
        currencyDisplay: 'symbol',
        style: 'currency',
    });
};

export const UserFormView: React.FC<Props> = ({ customerData, onFormFinish }: Props) => {
    const [form] = Form.useForm<FormRules>();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: FormRules): Promise<void> => {
        setLoading(true);
        try {
            await form.validateFields();
            await onFormFinish(values);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='flex-container'>
            <div className='userform-splitcontainer'>
                <Card className='view-card'>
                    <Form form={form} requiredMark={false} layout='vertical' onFinish={onFinish}>
                        <Space direction='vertical'>
                            <div className='view-icon-circle'>
                                <ShieldFillIcon className='outer-shield' />
                                <ShieldFillIcon className='line-shield' />
                                <ShieldFillIcon className='inner-shield' />
                                <ShieldCheckIcon className='shield-check' />
                            </div>
                            <Title level={3} className='bold text-centered'>
                                {
                                    'Herzlichen Glückwunsch zu Ihrer Kfz-Versicherung bei der SG IFFOXX.'
                                }
                            </Title>
                            <Paragraph className='userform-paragraph'>
                                {
                                    'Um Ihre Versicherung abschließen zu können, benötigen wir noch folgende Informationen von Ihnen:'
                                }
                            </Paragraph>
                            <Paragraph className='bold'>{'Zahlungsdaten'}</Paragraph>
                            <Typography className='bold'>
                                {'Zahlung '} <b className='userform-sepa'>{'S€PA'}</b>
                            </Typography>
                        </Space>
                        <Item
                            name='iban'
                            label='IBAN'
                            normalize={(value: string): string => normalizeIban(value, true)}
                            rules={[
                                {
                                    type: 'string',
                                    required: true,
                                    message: 'Bitte eine gültige 22 Zeichen lange IBAN eintragen',
                                    validator: (_, value: string) => ibanValidator('DE' + value),
                                },
                            ]}
                        >
                            <Input
                                maxLength={20}
                                addonBefore={
                                    <Item noStyle>
                                        <div>DE</div>
                                    </Item>
                                }
                            />
                        </Item>
                        <Paragraph>
                            {'Der Versicherungsnehmer muss auch Kontoinhaber sein.'}
                        </Paragraph>
                        <Typography>{'Zahlungweise'}</Typography>
                        <Paragraph className='bold'>{customerData.rate.paymentMethod}</Paragraph>
                        <Item
                            name='sepaAccepted'
                            valuePropName='checked'
                            rules={[
                                {
                                    required: true,
                                    message: 'Bitte Einverständnis zur Lastschrift erklären',
                                    validator: (_, value) =>
                                        value === true ? Promise.resolve() : Promise.reject(),
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                            <Checkbox style={{ marginTop: '24px' }}>
                                {
                                    'Ich ermächtige SG IFFOXX die Versicherungsprämie von meinem Konto mittel Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die auf mein Konto gezogenen Lastschriften einzulösen.'
                                }
                                <Typography className='small-text'>
                                    <b>{'Hinweis: '}</b>
                                    {
                                        'Ich kann innerhalb von 8 Wochen beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.'
                                    }
                                </Typography>
                            </Checkbox>
                        </Item>
                        <Item
                            name='privacyNoticeAccepted'
                            valuePropName='checked'
                            rules={[
                                {
                                    required: true,
                                    message: 'Bitte die Datenschutzhinweise akzeptieren',
                                    validator: (_, value) =>
                                        value === true ? Promise.resolve() : Promise.reject(),
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                            <Checkbox style={{ marginTop: '24px' }}>
                                {'Hiermit bestätige ich, dass ich die '}
                                <Link href={Documents.agb} target='_blank'>
                                    {'AGB'}
                                </Link>
                                {', '}
                                <Link href={Documents.cancellationPolicy} target='_blank'>
                                    {'Widerrufsbelehrung'}
                                </Link>
                                {', '}
                                <Link href={Documents.privacyPolicy} target='_blank'>
                                    {'Datenschutzhinweise'}
                                </Link>
                                {' und '}
                                <Link href={Documents.mediationRegulation} target='_blank'>
                                    {
                                        'Erstinformationen gemäß § 15 Versicherungsvermittlungsverordnung '
                                    }
                                </Link>
                                {'zur Kenntnis genommen habe.'}
                            </Checkbox>
                        </Item>
                        <Item
                            name='brokerContractAccepted'
                            valuePropName='checked'
                            rules={[
                                {
                                    required: true,
                                    message: 'Bitte Einverständnis mit dem Maklervertrag erklären',
                                    validator: (_, value) =>
                                        value === true ? Promise.resolve() : Promise.reject(),
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                            <Checkbox style={{ marginTop: '24px' }}>
                                {'Hiermit bestätige ich, dass ich mit dem '}
                                <b>{'Maklervertrag'}</b>
                                {' einverstanden bin.'}
                            </Checkbox>
                        </Item>
                        <div className='flex-container'>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                <div>
                                    <span>{'Angebot annehmen'}</span>
                                    <ArrowRightOutlined />
                                </div>
                            </Button>
                        </div>
                    </Form>
                </Card>
            </div>
            <div className='userform-splitcontainer'>
                <div className='userform-datacontainer'>
                    <Title className='userform-datacontainer-text' level={4}>
                        {'Versicherungsnehmer'}
                    </Title>
                    <Row className='row-gap'>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'Vorname'}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'Nachname'}
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text bold'>
                                {customerData.firstName}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text bold'>
                                {customerData.secondName}
                            </Typography>
                        </Col>
                    </Row>
                    <Row className='row-gap'>
                        <Col span={24}>
                            <Typography className='userform-datacontainer-text'>
                                {'Straße / Hausnummer / Adresszusatz'}
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Typography className='userform-datacontainer-text bold'>
                                {customerData.street}
                            </Typography>
                        </Col>
                    </Row>
                    <Row className='row-gap'>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'Postleitzahl'}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>{'Ort'}</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text bold'>
                                {customerData.postcode}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text bold'>
                                {customerData.location}
                            </Typography>
                        </Col>
                    </Row>
                    <Row className='row-gap'>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'EMail'}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'Telefon'}
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text bold'>
                                {customerData.email}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text bold'>
                                {customerData.phoneNumber}
                            </Typography>
                        </Col>
                    </Row>
                </div>
                <div className='userform-datacontainer'>
                    <Title className='userform-datacontainer-text' level={4}>
                        {'Versicherungsprämie'}
                    </Title>
                    <Row className='row-gap'>
                        <Col span={24}>
                            <Typography className='userform-datacontainer-text bold'>
                                {customerData.rate.deductibleVariant}
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'Haftpflicht'}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text text-right-aligned'>
                                {formatCurrency(customerData.rate.liabilityInsuranceNet)}
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'Teilkasko / Vollkasko'}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text text-right-aligned'>
                                {formatCurrency(customerData.rate.fullyComprehensiveNet)}
                            </Typography>
                        </Col>
                    </Row>

                    <Divider className='userform-divider' />

                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'Versicherungsprämie Netto ' + customerData.rate.paymentMethod}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text text-right-aligned'>
                                {formatCurrency(customerData.rate.rateNet)}
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'Versicherungssteuer 19%'}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text text-right-aligned'>
                                {formatCurrency(customerData.rate.tax)}
                            </Typography>
                        </Col>
                    </Row>

                    <Divider className='userform-divider' />

                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'Versicherungsprämie Brutto ' + customerData.rate.paymentMethod}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text text-right-aligned'>
                                {formatCurrency(customerData.rate.rateGross)}
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text'>
                                {'AutoHero ServiceFee'}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text text-right-aligned'>
                                {formatCurrency(customerData.rate.serviceFee)}
                            </Typography>
                        </Col>
                    </Row>

                    <Divider className='userform-divider' />

                    <Row>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text bold'>
                                {'Gesamtprämie ' + customerData.rate.paymentMethod}
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <Typography className='userform-datacontainer-text bold text-right-aligned'>
                                {formatCurrency(customerData.rate.totalGross)}
                            </Typography>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
