import './progress-breadcrumbs.scss';

interface Props {
    stage: number;
}

export const ProgressBreadcrumbs: React.FC<Props> = ({ stage }: Props) => {
    return (
        <div className='progress-breadcrumbs container'>
            <div className='progress-breadcrumbs circle active'>
                <span className='progress-breadcrumbs circle-label'>1</span>
                <span className='progress-breadcrumbs circle-title'>Verifizierung</span>
            </div>
            <span
                className={
                    'progress-breadcrumbs circle-connector ' + (stage >= 2 ? 'active' : 'inactive')
                }
            ></span>
            <div className={'progress-breadcrumbs circle ' + (stage >= 2 ? 'active' : 'inactive')}>
                <span className='progress-breadcrumbs circle-label'>2</span>
                <span className='progress-breadcrumbs circle-title'>SEPA&#8209;Mandat</span>
            </div>
            <span
                className={
                    'progress-breadcrumbs circle-connector ' + (stage >= 3 ? 'active' : 'inactive')
                }
            ></span>
            <div className={'progress-breadcrumbs circle ' + (stage >= 3 ? 'active' : 'inactive')}>
                <span className='progress-breadcrumbs circle-label'>3</span>
                <span className='progress-breadcrumbs circle-title'>Vertragsabschluss</span>
            </div>
        </div>
    );
};
