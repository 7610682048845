import { Image, Typography } from 'antd';
import './banner.scss';

const { Text } = Typography;

export const Banner: React.FC = () => {
    return (
        <div className='banner'>
            <Text className='banner-text'>{'Die'}</Text>
            <Image
                className='banner-embedded-image'
                src='/static/images/logo_white.svg'
                preview={false}
                height='28px'
            />
            <Text className='banner-text banner-after-image'>
                {'ist der exklusive Kfz-Versicherungspartner von '}
            </Text>
            <Text className='banner-text banner-after-image'>
                <b>{'Autohero'}</b>
            </Text>
            <Text className='banner-text'>{'!'}</Text>
        </div>
    );
};
