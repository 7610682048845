import { Image, Typography } from 'antd';
import { Routing } from './routing';

const { Link } = Typography;

export const AppHeader: React.FC = () => {
    return (
        <div style={{ display: 'flex' }}>
            <Link href={Routing.main}>
                <Image src='/static/images/logo.svg' preview={false} height='64px' />
            </Link>
            <div style={{ flex: '1 0 0' }}></div>
            <Image src='/static/images/logo_green.svg' preview={false} height='64px' />
        </div>
    );
};
