import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ShieldCheckSvg = (): JSX.Element => (
    <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 16 16'>
        <path d='M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z' />
    </svg>
);

export const ShieldCheckIcon = (props: Partial<CustomIconComponentProps>): JSX.Element => (
    <Icon component={ShieldCheckSvg} {...props} />
);
