import { CloseCircleOutlined } from '@ant-design/icons';
import { Card, Space, Typography } from 'antd';
import './linkid-invalid-view.scss';

const { Paragraph } = Typography;

export const LinkIdInvalidView: React.FC = () => {
    return (
        <div className='flex-container'>
            <Card className='view-card'>
                <Space direction='vertical'>
                    <div className='view-icon-circle'>
                        <CloseCircleOutlined className='cross' />
                    </div>
                    <Paragraph className='bold text-centered'>
                        {
                            'Ihre Verifizierung ist leider fehlgeschlagen. Kontaktieren Sie den Support für Hilfe.'
                        }
                    </Paragraph>
                    <Paragraph className='extrabold text-centered'>
                        {'Support-Hotline: +49 941-595648-0'}
                    </Paragraph>
                </Space>
            </Card>
        </div>
    );
};
