import { ArrowRightOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Card, Space, Typography } from 'antd';
import './verification-failed-view.scss';

const { Paragraph } = Typography;

interface Props {
    onRetry: () => void | Promise<void>;
}

export const VerificationFailedView: React.FC<Props> = ({ onRetry }: Props) => {
    return (
        <div className='flex-container'>
            <Card className='view-card'>
                <Space direction='vertical'>
                    <div className='view-icon-circle'>
                        <CloseCircleOutlined className='cross' />
                    </div>
                    <Paragraph className='bold text-centered'>
                        {
                            'Ihre Verifizierung ist leider fehlgeschlagen. Versuchen Sie es bitte erneut oder kontaktieren Sie den Support.'
                        }
                    </Paragraph>
                    <Paragraph className='bold text-centered'>
                        {'Support-Hotline: +49 941-595648-0'}
                    </Paragraph>
                </Space>
                <div className='flex-container'>
                    <Button type='primary' onClick={onRetry}>
                        <div>
                            <span>{'Erneut versuchen'}</span>
                            <ArrowRightOutlined />
                        </div>
                    </Button>
                </div>
            </Card>
        </div>
    );
};
