import { Navigate, Route, Routes } from 'react-router-dom';
import { MainPage } from '../main-page';
import { Documents } from '../views/user-form-view/documents';
import { Routing } from './routing';

export const RoutedContent = (): React.ReactElement | null => {
    return (
        <Routes>
            <Route path={Routing.main} element={<MainPage />} />
            <Route path={Documents.agb} />
            <Route path={Documents.cancellationPolicy} />
            <Route path={Documents.mediationRegulation} />
            <Route path={Documents.privacyPolicy} />

            <Route path='*' element={<Navigate to={Routing.main} replace />} />
        </Routes>
    );
};
