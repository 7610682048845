import { ArrowRightOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Space, Typography } from 'antd';
import { useState } from 'react';
import { ShieldCheckIcon } from '../../../lib/shared/icons';
import './postcode-check-view.scss';

const { Item } = Form;
const { Paragraph, Text } = Typography;

interface Props {
    onFormFinish: (values: FormRules) => void | Promise<void>;
}

interface FormRules {
    postcode: string;
}

export const PostcodeCheckView: React.FC<Props> = ({ onFormFinish }: Props) => {
    const [form] = Form.useForm<FormRules>();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: FormRules): Promise<void> => {
        setLoading(true);
        try {
            await form.validateFields();
            await onFormFinish(values);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='flex-container'>
            <Card className='view-card'>
                <Form form={form} requiredMark={false} onFinish={onFinish}>
                    <Space direction='vertical'>
                        <div className='view-icon-circle'>
                            <UserOutlined className='user' />
                            <ShieldCheckIcon className='check' />
                        </div>
                        <Paragraph className='bold text-centered'>
                            {'Deine Versicherung für dein Fahrzeug von '}
                            <Text className='bold text-blue'>{'Auto'}</Text>
                            <Text className='bold text-orange'>{'hero'}</Text>
                            <Text className='bold text-blue'>{':'}</Text>
                        </Paragraph>
                        <Paragraph className='text-centered'>
                            {
                                'Du kannst deine Kfz-Versicherung sofort bei der SG IFFOXX abschließen. Verifiziere dich hierzu nur kurz mit deiner Postleitzahl.'
                            }
                        </Paragraph>
                    </Space>
                    <Item
                        className='flex-container'
                        name='postcode'
                        label={<Text className='bold'>{'Deine Postleitzahl'}</Text>}
                        normalize={(input: string): string => input.replace(/\D/g, '')}
                        rules={[
                            {
                                required: true,
                                message: 'Bitte eine Postleitzahl eintragen',
                            },
                            {
                                pattern: /^[\d]{5}$/,
                                message: 'Ungültige Postleitzahl',
                            },
                        ]}
                    >
                        <Input className='postcode-input' maxLength={5} />
                    </Item>
                    <div className='flex-container'>
                        <Button type='primary' htmlType='submit' loading={loading}>
                            <div>
                                <span>{'Verifizierung prüfen'}</span>
                                <ArrowRightOutlined />
                            </div>
                        </Button>
                    </div>
                </Form>
            </Card>
        </div>
    );
};
