import { LikeFilled, StarFilled } from '@ant-design/icons';
import { Card, Space, Typography } from 'antd';
import './submitted-view.scss';

const { Paragraph, Title } = Typography;

interface Props {
    processNumber: string;
}

export const SubmittedView: React.FC<Props> = ({ processNumber }: Props) => {
    return (
        <div className='flex-container'>
            <Card className='view-card'>
                <Space direction='vertical'>
                    <div className='view-icon-circle'>
                        <LikeFilled className='thumbs' />
                        <StarFilled className='star left' />
                        <StarFilled className='star middle' />
                        <StarFilled className='star right' />
                    </div>
                    <Title className='bold text-primary' level={2}>
                        {'Vielen Dank für deinen Versicherungsabschluss.'}
                    </Title>
                    <Typography className='bold'>
                        {'Deine Vorgangsnummer ' + processNumber}
                    </Typography>
                    <Paragraph className='submitted-paragraph'>
                        {
                            'Dein Antrag ist bei uns eingegangen und du bekommst in Kürze eine E-Mail mit allen relevanten Unterlagen zu deiner Versicherung.'
                        }
                    </Paragraph>
                    <Title className='text-centered' level={3}>
                        {'Du hast noch Fragen?'}
                    </Title>
                    <div className='submitted-telephone-container'>
                        <Paragraph className='text-centered'>
                            <Paragraph className='submitted-noline'>
                                {'Wir sind werktags zwischen '}
                                <b>{'9 - 17 Uhr'}</b>
                                {' unter'}
                            </Paragraph>
                            <Paragraph className='submitted-noline'>
                                {'der Rufnummer '}
                                <b>{'+49 941-595648-0'}</b>
                            </Paragraph>
                            <Paragraph className='submitted-noline'>
                                {' für dich erreichbar.'}
                            </Paragraph>
                        </Paragraph>
                    </div>
                    <div className='submitted-email-container'>
                        <Paragraph className='text-centered'>
                            <Paragraph className='submitted-noline'>
                                {'Oder schreib uns eine'}
                            </Paragraph>
                            <Paragraph className='submitted-noline'>
                                {'E-Mail '}
                                <b>{'info@sgia.de'}</b>
                            </Paragraph>
                        </Paragraph>
                    </div>
                    <Paragraph>{'Beste Grüße'}</Paragraph>
                    <Paragraph>
                        {'Dein '}
                        <b className='text-sgi-green'>{'SG IFFOXX-Team'}</b>
                        {'!'}
                    </Paragraph>
                </Space>
            </Card>
        </div>
    );
};
